
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Lifecycle Hooks
  beforeCreate() {
    console.log('componentBeforeCreate', this);
  },

  created() {
    console.log('componentCreated', this);
  },

  beforeMount() {
    console.log('componentBeforeMount', this);
  },

  mounted() {
    console.log('componentMounted', this);
  },

  beforeDestroy() {
    console.log('componentBeforeDestroy', this);
  },

  destroyed() {
    console.log('componentDestroyed', this);
  },

  // Component Data
  data: function () {
    // Must return an object
    return {
      name: 'Jimmy',
      age: 25,
      like: ['Tennis', 'Chess', 'Football']
    };
  },
  // Component Methods
  methods: {
    openAlert: function () {
      var self = this;
      self.$app.dialog.alert('Hello World');
    }
  },
  // Page Events
  on: {
    pageMounted: function (e, page) {
      console.log('pageMounted', page);
    },
    pageInit: function (e, page) {
      console.log('pageInit', page);
    },
    pageBeforeIn: function (e, page) {
      console.log('pageBeforeIn', page);
    },
    pageAfterIn: function (e, page) {
      console.log('pageAfterIn', page);
    },
    pageBeforeOut: function (e, page) {
      console.log('pageBeforeOut', page);
    },
    pageAfterOut: function (e, page) {
      console.log('pageAfterOut', page);
    },
    pageBeforeRemove: function (e, page) {
      console.log('pageBeforeRemove', page);
    }
  },
  id: '3a42be48a9',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=title>Component Page</div></div></div><div class=page-content><div class="block block-strong"><p>Component page is alos compiled with Template7, but it has much more functionality. In addition to Template7 page it has lifecycle hooks, events handling and data managment.</p><p>It is useful to use Component page when you need page-specific logic.</p></div><div class=block-title>Events Handling</div><div class="block block-strong"><a href=# class="button button-raised" @click=openAlert>Open Alert</a></div><div class=block-title>Page Component Data</div><div class="block block-strong"><p>Hello! My name is ';
      r += c(ctx_1.name, ctx_1);
      r += '. I am ';
      r += c(ctx_1.age, ctx_1);
      r += ' years old.</p><p>I like to play:</p><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.like, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li>';
          r += c(ctx_2, ctx_2);
          r += '</li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class=block-title>Extended Context</div><div class="block block-strong"><p>Component page context as Template7 page context is also extended with some additional variables.</p><h4>$route</h4><p>Contains properties of the current route:</p><ul style=padding-left:25px><li><b>$route.url</b>: ';
      r += c(ctx_1.$route.url, ctx_1);
      r += '</li><li><b>$route.path</b>: ';
      r += c(ctx_1.$route.path, ctx_1);
      r += '</li><li><b>$route.params</b>: ';
      r += Template7Helpers.js.call(ctx_1, 'return JSON.stringify(this.$route.params)', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li><li><b>$route.hash</b>: ';
      r += c(ctx_1.$route.hash, ctx_1);
      r += '</li><li><b>$route.query</b>: ';
      r += Template7Helpers.js.call(ctx_1, 'return JSON.stringify(this.$route.query)', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</li></ul><h4>$root</h4><p>Root data & methods:</p><ul style=padding-left:25px><li><b>$root.user.firstName</b>: ';
      r += c(ctx_1.$root.user.firstName, ctx_1);
      r += '</li><li><b>$root.user.lastName</b>: ';
      r += c(ctx_1.$root.user.lastName, ctx_1);
      r += '</li><li><a @click=$root.helloWorld()>$root.helloWorld()</a></li></ul><h4>$theme</h4><p>Currently active theme:</p><ul style=padding-left:25px><li><b>$theme.ios</b>: ';
      r += c(ctx_1.$theme.ios, ctx_1);
      r += '</li><li><b>$theme.md</b>: ';
      r += c(ctx_1.$theme.md, ctx_1);
      r += '</li><li><b>$theme.aurora</b>: ';
      r += c(ctx_1.$theme.aurora, ctx_1);
      r += '</li></ul></div></div></div>';
      return r;
    }(this);
  },

  style: `
  p {
    margin: 10px 0;
  }
`,
  styleScoped: false
};
    