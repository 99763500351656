export default {
  id: 'fd0dc4b002',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=settings-users><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><!--\n                <div class="left">\n                    <a href="#" class="link icon-only panel-open" data-panel=".panel-left-protect">\n                        <i class="icon material-icons">filter_list</i>\n                    </a>\n                </div>\n                --><div class="title sliding">Пользователи</div><div class=right><a href=# class="link icon-only" id=settings-user-add><i class=material-icons>person_add</i></a></div><!--<div class="title-large">\n                  <div class="title-large-text">Discipline</div>\n                </div>--></div></div><!-- Scrollable page content--><div class=page-content><form autocomplete=off><div class=data-table id=table-users><table><thead><tr><th class="input-cell table-users-partner"><span class=table-head-label>Дистрибьютор/производитель</span><div class=input><input type=text placeholder=Фильтр name=table-users-partner-input id=table-users-partner-input style="font-size: 10px;"></div></th><th class=table-users-email>e-mail</th><th class=table-users-phone>Телефон</th><th class="input-cell table-users-person"><span class=table-head-label>Контактное лицо</span><div class=input><input type=text placeholder=Фильтр name=table-users-person-input id=table-users-person-input style="font-size: 10px;"></div></th><th class=table-users-last>Последний вход</th></tr></thead><tbody></tbody></table></div></form></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};