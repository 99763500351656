import HomePage from '../pages/home.f7.html';
//import AttachmentPage from '../pages/attachment.f7.html';
import DeliveredGoodsPage from '../pages/delivered-goods.f7.html';
import ProtectionPage from '../pages/protection.f7.html';
//import AboutPage from '../pages/about.f7.html';
//import FormPage from '../pages/form.f7.html';
import RequestPage from '../pages/request.f7.html';
import ProtectPage from '../pages/protect.f7.html';
import EventsPage from '../pages/events.f7.html';
//import ProductPage from '../pages/product.f7.html';
import SettingsPage from '../pages/settings.f7.html';
import SettingsHelpPage from '../pages/settings-help.f7.html';
import SettingsReportsPage from '../pages/settings-reports.f7.html';

import SettingsMyPage from '../pages/settings-my.f7.html';

import SettingsUsersEditPage from '../pages/settings-users-edit.f7.html';

import SettingsPartnersPage from '../pages/settings-partners.f7.html';
import SettingsPartnersEditPage from '../pages/settings-partners-edit.f7.html';
import SettingsUsersPage from '../pages/settings-users.f7.html';

import AttachmentPopupPage from '../pages/attachment-popup.f7.html';
import NotificationPopupPage from '../pages/notification-popup.f7.html';
import ProtectionPopupPage from '../pages/print-protection-popup.f7.html';
import ProductPopupPage from '../pages/protection-product-popup.f7.html';

import DynamicRoutePage from '../pages/dynamic-route.f7.html';
//import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [
    {
        name: 'protection-product',
        path: '/protection-product/:prod_id/',
        popup : {
            component: ProductPopupPage,
            on : {
                open : function (popup) {
                    var router = this;
                    var app = router.app;
                    var prod_id = popup.route.params.prod_id;
                    app.methods.PageProductInit(prod_id);
                },
            },
        },
    },
    {
        path: '/',
        component: HomePage,
    },
    /*{
        path: '/about/',
        component: AboutPage,
    },*/
    {
        path: '/protection/',
        component: ProtectionPage,
        /*
        path: '/protection/:protection_id/',
        popup : {
            component: ProtectionPage,
            on : {
                open : function (popup) {
                    var router = this;
                    var app = router.app;

                    var userId = 1;
                    //console.log('userId - ' + userId);
                    var protection_id = popup.route.params.protection_id;
                    app.methods.PageProtectionInit(protection_id);
                },
            },
        },
        */
    },
    /*
    {
        path: '/attachment/',
        component: AttachmentPage,
    },
    */
    {
        path: '/print-attachment-popup/',
        popup : {
            component: AttachmentPopupPage,
            on : {
                open : function () {
                    var router = this;
                    var app = router.app;
                    app.methods.PageAttachmentInit();
                },
            },
        },
    },
    {
        path: '/print-notification-popup/',
        /*popup : {
            component: NotificationPopupPage,
            on : {
                open : function () {
                    var router = this;
                    var app = router.app;
                    app.methods.PageNotificationInit();
                },
            },
        },*/
        component: NotificationPopupPage,
    },
    {
        path: '/print-protection-popup/',
        popup : {
            component: ProtectionPopupPage,
            on : {
                open : function () {
                    var router = this;
                    var app = router.app;
                    app.methods.PagePrintProtectionInit();
                },
            },
        },
    },
    {
        path: '/delivered-goods/',
        component: DeliveredGoodsPage,
    },
    /*{
        path: '/form/',
        component: FormPage,
    },*/
    {
        path: '/protect/',
        component: ProtectPage,
    },
    {
        path: '/events/',
        component: EventsPage,
    },
    {
        path: '/request/',
        component: RequestPage,
    },
    /*{
        path: '/product/:id/',
        component: ProductPage,
    },*/
    {
        path: '/settings/',
        component: SettingsPage,
    },
    {
        path: '/settings-help/',
        component: SettingsHelpPage,
    },
    {
        path: '/settings-reports/',
        component: SettingsReportsPage,
    },
    {
        path: '/settings-my/',
        component: SettingsMyPage,
    },
    {
        name: 'settings-users-edit',
        path: '/settings-users-edit/:account_id/',
        popup : {
            component: SettingsUsersEditPage,
            on : {
                open : function (popup) {
                    var router = this;
                    var app = router.app;

                    var userId = 1;
                    //console.log('userId - ' + userId);
                    var account_id = popup.route.params.account_id;
                    app.methods.PageSettingUsersEditInit(account_id);
                },
            },
        },
    },
    {
        path: '/settings-partners/',
        component: SettingsPartnersPage,
    },
    {
        name: 'settings-partners-edit',
        path: '/settings-partners-edit/:partners_id/',
        popup : {
            component: SettingsPartnersEditPage,
            on : {
                open : function (popup) {
                    var router = this;
                    var app = router.app;

                    //var userId = 1;
                    //console.log('userId - ' + userId);
                    //var account_id = popup.route.params.account_id;
                    //app.methods.PageSettingUsersEditInit(account_id);
                },
            },
        },
    },
    {
        path: '/settings-users/',
        component: SettingsUsersPage,
    },
    {
        path: '/dynamic-route/blog/:blogId/post/:postId/',
        component: DynamicRoutePage,
    },
    /*{
        path: '/request-and-load/user/:userId/',
        async: function (routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;

            // App instance
            var app = router.app;

            // Show Preloader
            app.preloader.show();

            // User ID from request
            var userId = routeTo.params.userId;

            // Simulate Ajax Request
            setTimeout(function () {
                // We got user data from request
                var user = {
                    firstName: 'Vladimir',
                    lastName: 'Kharlampidi',
                    about: 'Hello, i am creator of Framework7! Hope you like it!',
                    links: [
                        {
                            title: 'Framework7 Website',
                            url: 'http://framework7.io',
                        },
                        {
                            title: 'Framework7 Forum',
                            url: 'http://forum.framework7.io',
                        },
                    ]
                };
                // Hide Preloader
                app.preloader.hide();

                // Resolve route to load page
                resolve(
                    {
                        component: RequestAndLoad,
                    },
                    {
                        context: {
                            user: user,
                        }
                    }
                );
            }, 1000);
        },
    },*/
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;