export default {
  id: 'cb1ed7c9b8',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=popup data-name=settings-partners-edit id=page-settings-partners-edit><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class="title sliding">Новый дистрибьютор</div><div class=right><a href=# class="link popup-close"><i class=material-icons>close</i></a></div></div></div><div class=page-content><form autocomplete=off id=page-settings-partners-edit_form><div class=block style="margin-top: 0; margin-bottom: 0;"><div class="row row-list-margin-top-0 row-list-margin-bottom-0" style="margin-top: 15px;"><div class="col-100 medium-50"><div class="list no-hairlines-md"><ul><li class=align-top><div class="item-content item-input" id=page-settings-partners-edit-name-div><div class=item-inner><!--<div class="item-title item-label">Название</div>--><div class=item-input-wrap><input type=text placeholder=Название id=page-settings-partners-edit-name-input name=page-settings-partners-edit-name-input></div></div></div></li></ul></div></div><div class="col-100 medium-50"><div class="list no-hairlines-md"><ul><li class=align-top><label class="item-checkbox item-content"><input type=checkbox name=page-settings-partners-edit-active-checkbox id=page-settings-partners-edit-active-checkbox value=1> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title>Заблокирован</div></div></label></li></ul></div></div></div><p class=row style="margin-top: 15px; margin-bottom: 15px;"><a href=# class="col button button-raised button-fill" id=settings-distributor-edit-apply-button>Сохранить</a></p></div></form></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};